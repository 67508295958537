import React, { useState, useMemo } from 'react';
import { noop } from 'lodash';
import FocusTrap from '../../../components/focus-trap';
import { useSelector, useActions } from '../../../components/runtime-context';
import { withPromisifiedCloseModal } from '../store/modal-actions';
import { getOpenedModals } from '../store/modal-selectors';
import styles from './modal-root.scss';

type ModalProps = {
  setRef?: () => void;
};

type ModalModule = {
  [modalType: string]: React.FC<any>;
};

type ImportModalsFunction = () => Promise<{
  default: ModalModule;
}>;

export const createModalRootComponent = ({
  importModals,
}: {
  importModals: ImportModalsFunction;
}) => {
  const ModalRoot: React.FC<ModalProps> = ({ setRef }) => {
    const openedModals = useSelector(getOpenedModals);

    const { closeModal } = useActions();
    const [modals, setModals] = useState<ModalModule | null>(null);

    const isEmpty = useMemo(() => {
      return Object.keys(openedModals).length === 0;
    }, [openedModals]);

    const getModal = (type: string) => {
      if (!modals) {
        importModals()
          .then((importedModals) => setModals(importedModals.default))
          .catch(noop);
      }
      return modals && type in modals ? modals[type] : null;
    };

    if (isEmpty) {
      return null;
    }

    return (
      <div className={styles.modalRoot}>
        {Object.entries(openedModals).map(
          ([type, { props, correlationId }], index) => {
            const closeModalWithResolve = (resolve = false) =>
              withPromisifiedCloseModal(
                closeModal({ type, resolve, correlationId }),
              );

            const Component = getModal(type);
            return (
              Component && (
                <div
                  id="new-blog-modal-root"
                  ref={setRef ? setRef : noop}
                  key={type}
                  style={{ zIndex: index }}
                >
                  <FocusTrap
                    className={styles.focusTrap}
                    active
                    onExit={closeModalWithResolve}
                  >
                    <Component {...props} closeModal={closeModalWithResolve} />
                  </FocusTrap>
                </div>
              )
            );
          },
        )}
      </div>
    );
  };

  return ModalRoot;
};
